.container {
  height: 100%;
  overflow-y: auto;
  background-color: var(--background-5) !important;
  box-shadow: inset -1px 0px 4px rgba(0, 0, 0, 0.117);
}

.worktypeTitle {
  margin: 16px !important;
}

.link {
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}

.timelineText {
  font-size: 12px;
  margin-right: 8px;
}

.timelineDot span {
  top: -2px;
  width: 8px;
  height: 8px;
}

.selectedItem {
  background-color: white;
  box-shadow: inset -1px 0px 4px rgba(0, 0, 0, 0.25);
}
