.left {
  height: 100%;
  left: 0;
  background-color: var(--background-2);
  border-right: 1px solid rgba(0, 0, 0, 0.06);
  box-shadow: inset -1px 0px 4px rgba(0, 0, 0, 0.25);
}

.right {
  height: 100%;
  right: 0;
  border-left: 1px solid rgba(0, 0, 0, 0.06);
}
