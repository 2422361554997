#root {
  height: 100%;
}

.ant-layout {
  height: 100vh;
  background: var(--color-white) !important;
}

header.ant-layout-header {
  padding: 0;
}

/* Radio group , Radio button */

.radioGroup .ant-radio-wrapper {
  margin-right: 24px;
}

.radioGroup .ant-radio + span {
  padding-right: 0;
  font-weight: normal;
  color: var(--color-black-4);
}
.radioGroup .ant-radio-wrapper:hover .ant-radio-inner,
.radioGroup.ant-radio:hover .ant-radio-inner,
.radioGroup .ant-radio-input:focus + .ant-radio-inner {
  border-color: var(--color-black-5);
  background-color: var(--color-white);
}
.radioGroup .ant-radio-checked::after,
.radioGroup .ant-radio-checked .ant-radio-inner {
  border-color: var(--color-black-5);
  background-color: var(--color-white);
}
.radioGroup .ant-radio-checked .ant-radio-inner::after {
  background-color: var(--color-black-5);
  z-index: 1;
}

/*        Checkbox           */

.checkbox .ant-checkbox-checked .ant-checkbox-inner {
  background-color: var(--color-black-5);
  border-color: var(--color-black-5);
}

.checkbox.ant-checkbox-wrapper:hover .ant-checkbox-inner,
.checkbox.ant-checkbox:hover .ant-checkbox-inner,
.ant-checkbox-input:focus + .ant-checkbox-inner,
.checkbox .ant-checkbox-checked::after {
  border-color: var(--color-black-5);
}

.checkbox.ant-checkbox-wrapper:hover .ant-checkbox-inner,
.checkbox.ant-checkbox:hover .ant-checkbox-inner,
.checkbox .ant-checkbox-input:focus + .ant-checkbox-inner {
  border-color: var(--color-black-5);
}

/* divider */

.divider.ant-divider {
  margin-top: 0;
  margin-bottom: 0;
}

/* Arrow table*/

.arrow svg {
  fill: var(--color-black-3);
}

.ant-table table {
  border-collapse: collapse !important;
}

/*    Table Row item table */
.ant-table-wrapper .ant-table-thead > tr > th {
  font-weight: 500;
}

.table .ant-table-thead .ant-table-cell {
  font-weight: 500;
  color: var(--color-black-4);
}

.table .ant-table-tbody .ant-table-cell {
  font-weight: normal;
  color: var(--color-black-3);
}

.table .ant-typography {
  margin-bottom: 0;
}

.table .ant-pagination-item-active,
.table .ant-pagination-item:focus-visible,
.table .ant-pagination-item:hover {
  border-color: var(--color-blue-1);
}
.table .ant-pagination-item-active a,
.table .ant-pagination-item:focus-visible a,
.table .ant-pagination-item:hover a {
  color: var(--color-blue-1);
}

.table .ant-pagination-prev:focus-visible .ant-pagination-item-link,
.table .ant-pagination-next:focus-visible .ant-pagination-item-link,
.table .ant-pagination-prev:hover .ant-pagination-item-link,
.table .ant-pagination-next:hover .ant-pagination-item-link {
  border-color: var(--color-blue-1);
  color: var(--color-blue-1);
}

.ant-table-sticky-scroll {
  visibility: hidden;
}

.ant-table-header.ant-table-sticky-holder {
  box-shadow: 0px 1px 4px rgb(0 0 0 / 15%);
  z-index: 3;
}

.isGroupHeader .ant-table-cell {
  border-bottom: none;
}

.rowItem {
  cursor: pointer;
}
.rowItem:hover .ant-table-cell {
  color: var(--color-black-4);
}

.rowItem:hover .arrow svg {
  fill: var(--color-black-4);
}

.buttonPrimary.ant-btn-primary {
  background: var(--color-blue-1);
  border-color: var(--color-blue-1);
}
.buttonPrimary.ant-btn-primary:hover,
.buttonPrimary.ant-btn-primary:focus {
  background: var(--color-blue-1);
  border-color: var(--color-blue-1);
}

.buttonLink {
  font-size: 12px;
}

.buttonLink.ant-btn-link {
  padding: 0;
  color: inherit;
  font-size: 12px;
}

.buttonLink.cancelLinkBtn:hover {
  color: var(--color-red-1);
}

li:last-child .buttonLink {
  vertical-align: bottom;
}

.ant-dropdown-menu {
  max-height: 250px;
  overflow: auto;
}

.ant-carousel .slick-arrow {
  position: absolute !important;
  top: 55% !important;
  transform: translateY(-50%) !important;
  width: 32px !important;
  height: 32px !important;
  display: flex !important;
  justify-content: center !important;
  align-items: center !important;
  background-color: var(--color-white) !important;
  border: 1px solid var(--color-gray-1) !important;
  transition: background-color 0.5s !important;
  border-radius: 50% !important;
  cursor: pointer !important;
  z-index: 1 !important;
}

.ant-carousel .slick-arrow.slick-disabled {
  display: none !important;
  visibility: hidden;
}

.ant-carousel .slick-arrow:hover {
  background-color: var(--color-gray-1) !important;
}

.ant-carousel .slick-next {
  right: 20px !important;
}

.ant-carousel .slick-prev {
  left: 20px !important;
}

.ant-carousel .slick-prev,
.ant-carousel .slick-next {
  font-size: inherit !important;
  color: currentColor !important;
}

/* Ant Tabs */
.ant-tabs-top > .ant-tabs-nav::before,
.ant-tabs-bottom > .ant-tabs-nav::before,
.ant-tabs-top > div > .ant-tabs-nav::before,
.ant-tabs-bottom > div > .ant-tabs-nav::before {
  border-bottom: none !important;
}

.ant-notification-notice {
  background-color: #fafafa !important;
  border: 1px solid #d9d9d9;
}

.ant-notification-notice-error {
  background-color: #fff2f0 !important;
  border: 1px solid #ffccc7;
}

.ant-notification-notice-warning {
  background-color: #fffbe6 !important;
  border: 1px solid #ffe58f;
}

.ant-notification-notice-success {
  background-color: #f6ffed !important;
  border: 1px solid #b7eb8f;
}

.ant-notification-notice-info {
  background-color: #e6f7ff !important;
  border: 1px solid #91d5ff;
}

.ant-timeline-item {
  padding: 0 16px;
  padding-bottom: 14px !important;
}

.ant-timeline-item-head {
  background-color: transparent !important;
  margin: 0 16px;
}

.ant-timeline-item-tail {
  top: 32px !important;
  margin: 0 16px;
  height: calc(100% - 20px) !important;
  border-color: rgba(0, 0, 0, 0.06) !important;
}

.ant-timeline-item-head-custom {
  top: 20.5px !important;
}

.ant-timeline-item-content {
  top: 8px !important;
}

.ant-timeline-item-last > .ant-timeline-item-content {
  min-height: inherit !important;
}

.ant-collapse-content {
  flex: 1 1 auto !important;
  overflow: auto !important;
  padding: 4px 0;
  border-bottom: 0 !important;
}

.ant-card-grid-hoverable:hover {
  transform: scale(1.03);
}

.ant-image-preview-mask {
  background-color: rgba(0, 0, 0, 0.8) !important;
}

img {
  border-radius: 4px;
}

.ant-avatar-circle.avatarCenterText .ant-avatar-string {
  margin-top: -3px !important;
}
.tui-image-editor-container
  .color-picker-control
  .tui-colorpicker-palette-button {
  font-size: 4px !important;
  border-radius: 50% !important;
  height: 16px;
  width: 16px;
}
.tui-colorpicker-palette-preview {
  color: transparent !important;
}
.tui-image-editor-container .color-picker-control {
  width: auto !important;
}
