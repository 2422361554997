.radioGroup {
  display: 'flex';
  flex-direction: column;
  padding: 8px 0px;
  height: 210px;
  overflow-y: scroll;
  width: 95%;
  border: 1px solid #d9d9d9;
  border-radius: 4px;
  :global {
    .ant-tree {
      .ant-tree-node-content-wrapper:hover {
        background-color: transparent !important;
      }
    }
  }
}
.heading {
  font-size: 15px;
  font-weight: 500;
}
.secondary {
  font-size: 13px;
  font-weight: 400;
}
.modalComment {
  font-size: 14px;
  padding-top: 10px;
  padding-bottom: 4px;
  color: var(--color-gray-5);
}
.modalFooter {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0px 6px;
}
.footerButtonsContainer {
  display: flex;
  justify-content: center;
  align-items: center;
}
.flexAlignCenter {
  display: flex;
  align-items: center;
}
