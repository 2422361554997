.spin {
  width: 100%;
  margin: auto;
}
.userLog {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  margin: 6px 0;
}
.userIcon {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: auto 8px auto 0px;
  font-size: 18px;
  height: 25px;
  width: 25px;
  border: 1px solid black;
  border-radius: 50%;
  background-color: #ffc700;
}
.userDetail {
  margin: 0 8px 8px 8px;
}
