.container {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 16px;
  height: calc(100% - 32px);

  background: var(--color-white);
  box-shadow: inset 1px 0px 0px rgba(0, 0, 0, 0.06),
    inset -1px 0px 0px rgba(0, 0, 0, 0.06);
}

.header {
  margin-top: 32px;
}

.btnContainer {
  display: flex;
  gap: 16px !important;
  width: 100%;
  padding: 16px;
  margin-top: 0;
  background: var(--background-4);
  border-radius: 0px 0px 4px 4px;
}
.reviewedPackagesContainer {
  display: flex;
  align-items: center;
  margin: 0;
  padding: 8px 16px;
  color: var(--color-white);
  font-weight: bold;
  background: var(--color-blue-1);
  border-radius: 4px 4px 0px 0px;
}
.reviewedPackagesDrawerItem {
  padding: 16px;
  margin-right: 24px;
}
.reviewedPackageName {
  padding-left: 8px;
  font-weight: bold;
}
.reviewedPackagesDrawerItem:hover {
  background: var(--background-5);
  cursor: pointer;
  .reviewedPackageName {
    color: var(--color-blue-primary);
  }
}
.packageStatusBadge {
  top: -2px;
  width: 8px;
  height: 8px;
}
.footerContainer {
  position: absolute;
  width: 100%;
  padding: 16px 32px;
}

.fifoBtn {
  width: 200px;
  text-align: left;
}
