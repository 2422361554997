.wrapper {
  height: 100vh;
  width: 100vw;
  margin: 0;
  padding: 0;
  display: flex;
  position: relative;
}
.leftContainer {
  display: flex;
  flex-direction: column;
  width: 50vw;
  height: 100%;
  z-index: 2;
}
.textContainer {
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: flex-start;
  padding: 36px 36px 0px 100px;
  height: 50%;
}
.rightContainer {
  position: absolute;
  bottom: 0;
  right: 0;
  padding: 0;
  margin: 0;
  display: flex;
  justify-content: flex-end;
  align-items: flex-end;
  width: 50vw;
  height: 100vh;
  svg {
    max-width: 100%;
  }
}
.backToHomepageButton {
  margin: 4px 0 0 0;
  cursor: pointer;
}
.p0m0 {
  padding: 0;
  margin: 0;
}
.secondaryText {
  color: #00000073;
}
