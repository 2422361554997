.loginLayout {
  display: flex;
  justify-content: center;
  align-items: center;
}

.headers {
  width: 100%;
  margin: 10px 0;
  max-height: 100px;
  background-color: white;
  border-bottom: 1.24444px solid rgba(0, 0, 0, 0.06);
}
