.collapse {
  :global {
    .ant-collapse-header {
      border-bottom: none;
      padding: 0 !important;
      font-weight: 600;
      align-items: center !important;
    }
    .ant-collapse-content {
      padding-top: 10px;
    }
    .ant-collapse-content > .ant-collapse-content-box {
      padding: 0;
      overflow-x: hidden;
    }
  }
}
.panelHeader {
  margin-right: 16px;
  font-size: 14px;
  font-weight: 600;
}
.spin {
  width: 600px;
  margin: 8px auto;
}
.avatar {
  margin: 4px 16px 4px 4px;
  min-width: 48px;
  height: 48px;
  display: flex;
  justify-content: center;
  align-items: center;
  box-shadow: var(--box-shadow-container);
}
