.outerLayout {
  background: var(--background-2) !important;
  padding: 16px;
}

.wrapper {
  padding: 16px !important;
  background: white;
  border: 1px solid #d9d9d9;
  box-shadow: 0px 2px 4px rgb(0 0 0 / 6%);
  border-radius: 2px;
}

.spin {
  margin: auto;
}

.formHeader {
  background-color: var(--background-1);
  border-bottom: 1px solid #d9d9d9;
}

.formItemSelect {
  min-width: 300px;
}

.contentWrapper {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  height: 100%;
  overflow: hidden;
  position: relative;
}

.contentContainer {
  margin-top: 16px;
  padding-bottom: 56px;
  overflow: auto;
  width: 100%;
  display: flex;
}

.contentHeader {
  position: relative;
  width: 100%;
  padding: 0 16px;
}

.formGenerator {
  margin: 0 auto;
  width: 100%;

  :global {
    .ant-input[disabled] {
      color: black;
    }
    .ant-select-disabled.ant-select:not(.ant-select-customize-input)
      .ant-select-selector {
      color: black;
    }
    .ant-radio-disabled .ant-radio-inner {
      color: black;
    }
    .ant-input-number-disabled {
      color: black;
    }
    .ant-select-disabled.ant-select-multiple .ant-select-selection-item {
      color: black;
    }
    .ant-radio-disabled .ant-radio-inner::after {
      background-color: #1890ff;
    }
  }
}

.nestContent {
  border: 1px solid #d9d9d9;
  border-radius: 2px;
  padding: 8px;
  :global {
    .ant-form-item {
      margin: 8px 0;
    }
    .ant-empty-normal {
      margin: 0;
    }
  }
}

.newFieldContainer {
  max-height: 450px;
  overflow-y: auto;
}

.publishedItemForm {
  width: 650px;
}
