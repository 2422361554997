.detailBlock {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 8px;
  gap: 8px;

  width: 607px;
  height: 256px;
  overflow-y: hidden;

  background: #eff1f3;
  border-radius: 4px;

  flex: none;
  order: 0;
  align-self: stretch;
  flex-grow: 0;
}
.flex {
  display: flex;
  align-items: center;
  margin: 0;
  padding: 0;
}

.formItem {
  display: block !important;
  width: 100%;
  margin-bottom: 16px;
  label {
    font-weight: 500;
    color: #acacb3;
  }
}

.outerLayout {
  background: #f9f9f9 !important;
  min-height: 115vh !important;
  padding: 16px;
}

.additionalInfo {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 0px;
  border-radius: 4px 0px 0px 4px;
  border-right: 2px solid #eff1f3;
  background-color: #ffffff;
  width: 50%;
}
// Groups Blocks
.groupsBlock {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 0px;
  background-color: #ffffff;
  width: 110px;
  height: 80px;
  border-radius: 4px;
  cursor: pointer;
}
.groupActionOption {
  border: none;
  height: 20px;
  width: 15px;
  padding: 0 16px 0 0;
  margin: 0 4px;
}
.workTypesCountButton {
  border: 1px solid #ffffff;
  height: auto;
  width: auto;
  margin: 0 16px 0 0;
  padding: 0;
  z-index: 2;
  box-shadow: 0px 0px 1px rgba(0, 0, 0, 0.039),
    0px 0.5px 1.5px rgba(0, 0, 0, 0.19);
  &:hover {
    color: #000000;
  }
  &:focus {
    border: 1px solid #ffca55;
    color: #000000;
    box-shadow: 0px 0px 1px rgba(0, 0, 0, 0.039),
      0px 0.5px 1.5px rgba(0, 0, 0, 0.19);
  }
}
.highlightGroupOption {
  border: 1px solid #ffca55;
  color: #000000;
  box-shadow: 0px 0px 1px rgba(0, 0, 0, 0.039),
    0px 0.5px 1.5px rgba(0, 0, 0, 0.19);
}
.deletedGroup {
  border: 1px solid #ff6e55;
  color: #d84224;
  box-shadow: 0px 0px 1px rgba(0, 0, 0, 0.039),
    0px 0.5px 1.5px rgba(0, 0, 0, 0.19);
  &:hover {
    border: 1px solid #ff6e55;
    box-shadow: 0px 0px 1px rgba(0, 0, 0, 0.039);
  }
  &:focus {
    border: 1px solid #ff6e55;
    box-shadow: 0px 0px 1px rgba(0, 0, 0, 0.039);
  }
}
.addGroupButton {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 0px;
  margin: 0px;
  background-color: #d3d9db;
  width: 110px;
  height: 80px;
  box-shadow: 0px 0px 1px rgba(0, 0, 0, 0.039),
    0px 0.5px 1.5px rgba(0, 0, 0, 0.19);
  border-radius: 4px;
}
.groupName {
  width: 100%;
  padding: 0 12px;
  margin: 0 0 5px 0;
  font-weight: 600;
  box-shadow: 0px 1px 0px #f0f0f0;
  border-radius: 4px 4px 0px 0px;
  text-align: center;
}
.number {
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  margin-bottom: 0;
  padding-bottom: 0;
}
.countedItems {
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 24px;
  color: #acacb3;
  margin-bottom: 0;
  padding-bottom: 0;
}

.uploadComponent {
  width: 100% !important;
  a {
    color: #000 !important;
  }
  .ant-form-item-control-input-content {
    width: 100% !important;
  }
  .ant-upload-list-item-info {
    width: 100% !important;
  }
  .ant-upload-span {
    width: 100% !important;
  }
  .ant-upload-list-text {
    background: #fff !important;
  }
}
.preloadedFilesList {
  display: flex;
  align-items: center;
  margin: 0;
  padding: 5px 0;
  flex-direction: column;
  justify-content: flex-start;
  width: 100%;
  // padding-top: 5px;
  // padding-bottom: 5px;
  background: #fff;
  border-radius: 4px;
  height: 206px;
  overflow-y: scroll;
}

.row-dragging {
  background: #fafafa;
  border: 1px solid #ccc;
}

.row-dragging tr {
  background-color: red;
  padding: 16px;
}

.row-dragging .drag-visible {
  visibility: visible;
  padding-left: 30px;
  padding-right: 30px;
}
///////////////////////

.wrapper {
  padding: 16px !important;
  background: white;
  border: 1px solid #d9d9d9;
  box-shadow: 0px 2px 4px rgb(0 0 0 / 6%);
  border-radius: 2px;
}

.spin {
  margin: auto;
}

.formHeader {
  background-color: #f9f9f9;
  padding: 16px 34px;
  //border-bottom: 1px solid #d9d9d9;
}

.formItemSelect {
  min-width: 300px;
}

.contentWrapper {
  display: flex;
  height: 100%;
  overflow: hidden;
  position: relative;
}

.contentContainer {
  margin-top: 56px;
  padding-bottom: 56px;
  overflow: auto;
  width: 100%;
  display: flex;
}

.contentHeader {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  padding: 0 16px;
}

.formGenerator {
  margin: 0 auto;
  width: 100%;

  :global {
    .ant-input[disabled] {
      color: black;
    }
    .ant-select-disabled.ant-select:not(.ant-select-customize-input)
      .ant-select-selector {
      color: black;
    }
    .ant-radio-disabled .ant-radio-inner {
      color: black;
    }
    .ant-input-number-disabled {
      color: black;
    }
    .ant-select-disabled.ant-select-multiple .ant-select-selection-item {
      color: black;
    }
    .ant-radio-disabled .ant-radio-inner::after {
      background-color: #1890ff;
    }
  }
}

.nestContent {
  border: 1px solid #d9d9d9;
  border-radius: 2px;
  padding: 8px;
  :global {
    .ant-form-item {
      margin: 8px 0;
    }
    .ant-empty-normal {
      margin: 0;
    }
  }
}

.newFieldContainer {
  max-height: 450px;
  overflow-y: auto;
}

.publishedItemForm {
  width: 650px;
}
