.contentLayout {
  height: 100%;
}

.header {
  padding: 8px 32px;
}

.headerExtras {
  border-bottom: 1px solid var(--color-light-grey);
  padding: 0px 16px 16px 16px;
}

.breadcrumbContainer {
  margin: 16px 0 0 32px;
}

.worktypeFilterTree {
  :global {
    .ant-select-arrow,
    .ant-select-clear {
      margin-right: 4px;
    }
  }
}

.wrapper {
  padding: 0 0px 16px 0px;
  overflow: auto;
  background: var(--background-4);
}

.statusText {
  border-radius: 20px;
}

.woDetails {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  margin-top: 16px;
  padding: 16px 32px 24px 16px;
  background: white;
  box-shadow: 0px 0.333333px 16px rgba(0, 0, 0, 0.01),
    0px 1.25px 5px rgba(0, 0, 0, 0.06);
  border-radius: 4px;
}

.customerInformation {
  display: flex;
  width: 57%;
  min-width: 500px;
  padding: 0px 16px 22px 16px;
  flex-direction: column;
  align-items: flex-start;
  gap: 8px;
  align-self: stretch;
}
.customerInformationHeader {
  display: flex;
  padding: 16px 16px 0px 0px;
  align-items: center;
  gap: 8px;
  align-self: stretch;
}
.jobDetails {
  padding: 8px 16px;
  width: 40%;
  min-width: 500px;
  height: 260px;
  border-radius: 6px;
  border: 1px solid var(--neutral-5, #d9d9d9);
  background: #fff;
  /* Elevation Dashboard */
  box-shadow: 0px 1.25px 5px 0px rgba(0, 0, 0, 0.06),
    0px 0.3333333134651184px 16px 0px rgba(0, 0, 0, 0.01);
}
.sectionContent {
  padding: 16px 32px;
  background: white;
  // box-shadow: 0px 0.333333px 16px rgba(0, 0, 0, 0.01),
  //   0px 1.25px 5px rgba(0, 0, 0, 0.06);
  border-radius: 4px;
  position: relative;
}
.sectionContent2 {
  padding: 16px 32px;
  background: white;
  box-shadow: 0px 2px 8px 0px rgba(0, 0, 0, 0.15);
  border-radius: 4px;
  min-width: 500px;
  overflow-x: auto;
}
.workItemsProgressContainer {
  display: flex;
  justify-content: space-between;
  width: 100%;
}
.filtersContainer {
  display: flex;
  padding: 8px;
  justify-content: space-between;
  align-items: flex-start;
  align-self: stretch;
  min-width: 500px;
  overflow-x: auto;
  flex-wrap: wrap;
  border-radius: 6px;
  border: 1px solid var(--neutral-4, #f0f0f0);
  background: var(--neutral-2, #fafafa);
}
.badge {
  display: flex;
  padding: 0px 8px;
  margin-left: 8px;
  justify-content: center;
  align-items: center;
  border-radius: 10px;
  background: var(--neutral-4, #f0f0f0);
}

.removeButtonActive {
  :global {
    .ant-btn:focus {
      color: #000000d9 !important;
      border-color: #d9d9d9 !important;
    }
    .ant-btn:active {
      color: #000000d9 !important;
      border-color: #d9d9d9 !important;
    }
    .ant-btn:hover {
      color: #000000d9 !important;
      border-color: #d9d9d9 !important;
    }
  }
}

.sortButton {
  color: var(--shades-of-black-black-olive-70, #c7c7cc);
  display: flex;
  gap: 8px;
  align-items: center;
}
.appointmentsHeader {
  padding-bottom: 16px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: #fff;
}
.appointmentCardsContainer {
  padding: 16px 0;
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 16px;
}
.flexCenter {
  display: flex;
  align-items: center;
}
.pageSpin {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  min-height: 500px;
}
