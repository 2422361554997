.container {
  padding: 16px 0;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
  width: 100%;
  background: #fafcfe;
  box-shadow: inset -1px 0px 4px rgba(0, 0, 0, 0.117);
  overflow: 'auto';
}

.scrollableContainer {
  width: 100%;
  max-height: 45vh;
  overflow-y: scroll;
  color: #5d5c5cfb;
}

.link {
  color: gray;
  text-overflow: ellipsis;
  max-width: 220px;
  overflow: hidden;
  white-space: nowrap;
  .fileTextOutlined {
    margin: 0 4px 0 2px;
    color: gray;
  }
}
.link:hover {
  color: #1890ff;
  .fileTextOutlined {
    color: rgba(255, 186, 59, 1);
  }
}
.linkA {
  text-decoration: none;
  color: inherit;
}
.addressLink {
  cursor: pointer;
}
.addressLink:hover {
  color: #1890ff;
}

.name {
  font-size: 15px;
  margin: 0;
  padding: 0;
}
.subtext {
  font-size: 10px;
  margin: -4px 0 0 0;
  padding: 0;
}

.statusText,
.appointmentStatusText {
  text-transform: uppercase;
  border-radius: 20px;
}

.teamContainer {
  padding: 8px 0 8px 42px;
  width: 100%;
  margin: 0 0 4px 0;
}

.spaceContent {
  align-items: start;
  padding: 0 0 0 42px;
  width: 100%;
}

.spaceContent {
  color: rgba(71, 71, 77, 1);
  .pushpin {
    color: rgba(71, 71, 77, 1);
  }
  .phone {
    color: rgba(71, 71, 77, 1);
  }
}
.spaceContent:hover {
  .pushpin {
    color: #d72d2d;
  }
  .phone {
    color: #9bb389;
  }
}

.spacePanel {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  width: 100%;
  padding: 0 0 0 16px;
}

.appointmentCol:hover {
  background-color: rgba(2, 97, 163, 0.05);
}
.hover:hover {
  background-color: rgba(2, 97, 163, 0.05);
}
// SectionCollapse
.collapse {
  :global {
    .ant-collapse-header {
      border-bottom: none;
      padding: 0 !important;
      font-weight: 600;
      align-items: center !important;
    }
    .ant-collapse-header-text {
      margin-top: -6px;
      width: 100%;
      .ant-space {
        align-items: start !important;
      }
    }
    .ant-collapse-content > .ant-collapse-content-box {
      padding: 0;
      overflow-x: hidden;
    }
  }
}
.panelHeader {
  margin: 0 16px 16px 0;
  font-size: 14px;
  font-weight: 600;
  width: 150px;
}
