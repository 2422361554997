.container {
  position: relative;
  margin-left: auto;
  margin-right: auto;
  padding-right: 15px;
  padding-left: 15px;
}
@media (min-width: 476px) {
  .container {
    padding-right: 15px;
    padding-left: 15px;
  }
}
@media (min-width: 768px) {
  .container {
    padding-right: 15px;
    padding-left: 15px;
  }
}
@media (min-width: 992px) {
  .container {
    padding-right: 15px;
    padding-left: 15px;
  }
}
@media (min-width: 1200px) {
  .container {
    padding-right: 15px;
    padding-left: 15px;
  }
}
@media (min-width: 476px) {
  .container {
    width: 100%;
  }
}
@media (min-width: 768px) {
  .container {
    width: 720px;
    max-width: 100%;
  }
}
@media (min-width: 992px) {
  .container {
    width: 960px;
    max-width: 100%;
  }
}
@media (min-width: 1200px) {
  .container {
    width: 1140px;
    max-width: 100%;
  }
}
@media (min-width: 1400px) {
  .container {
    width: 1340px;
    max-width: 100%;
  }
}

.modal {
  max-width: unset;
  margin: unset;
  top: 0;
  width: 100%;
  height: 100vh;
  padding-bottom: 0;
}
.imgEditModal {
  :global {
    .FIE_topbar-save-wrapper {
      display: none !important;
    }
  }
}
.modalFooter {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-top: 4px;
  padding-bottom: 4px;
}

.thumbnail {
  cursor: pointer;
  position: relative;
  z-index: 0;
  background: #000;
  height: 155px;
  border-color: transparent;
  box-shadow: 0px 0.333333px 16px rgba(0, 0, 0, 0.01),
    0px 1.25px 5px rgba(0, 0, 0, 0.06);
  border-radius: 4px;
}

.image {
  object-fit: cover;
  width: 100%;
  height: 100%;
  display: block;
}

.radio {
  position: absolute;
  top: 4px;
  right: 4px;
  width: 24px;
  height: 24px;
  background: rgba(255, 255, 255, 0.5);
  z-index: 1;
  border-radius: 50%;
  display: inline-block;
  border: 2px solid #fff;
}

.radio.selected::after {
  content: '';
  position: absolute;
  top: 0px;
  right: 0px;
  width: 20px;
  height: 20px;
  background: var(--color-blue-0);
  z-index: 2;
  border-radius: 50%;
  display: inline-block;
}

.submitButton:not([disabled]) {
  background-color: #64d59f;
  border: none;
  color: #fff;
}

.modalPreview {
  display: flex;
  justify-content: center;
  width: 100%;
}

.modalPreview img {
  height: 100%;
  max-height: 80vh;
  max-width: 100%;
}

.modalPreviewFooter {
  padding-top: 4px;
  padding-bottom: 4px;
  width: 100%;
}

.cropContainer {
  position: 'relative';
  width: '100%';
  height: 200;
  background: '#333';
}
.cropButton {
  flex-shrink: 0;
  margin-left: 16;
}

.sliderContainer {
  display: flex;
  align-items: center;
  flex: 1;
}
.sliderLabel {
  margin-right: 16px;
  margin-left: 16px;
  font-weight: 600;
}
.slider {
  padding: '22px 0px';
  margin-left: 32;
  width: 100%;
}

.controlSliders {
  padding-top: 24px;
  padding-bottom: 16px;
  display: flex;
  justify-content: center;
}

.controlButtons {
  align-items: center;
  justify-content: center;
  padding-top: 16px;
  padding-bottom: 24px;
}

//imageEdit
.editor {
  :global {
    .ant-modal-content {
      background-color: black !important;
      .ant-modal-close-x .ant-modal-close-icon {
        color: white;
      }
    }
    .ant-modal-footer {
      border: none !important;
      background-color: #1e1e1e !important;
    }
    .ant-modal-header {
      border: none !important;
      background-color: black !important;
      .ant-modal-title {
        color: white;
      }
    }
  }
}
