.contentLayout {
  height: 100%;
}

.wrapper {
  padding: 16px 16px 16px 16px;
  overflow: auto;
  background: var(--background-2);
}

.headerContainer {
  margin: 16px 0px;
  padding: 16px;
  background: white;
  border: 1px solid #d9d9d9;
  box-shadow: 0px 2px 4px rgb(0 0 0 / 6%);
  border-radius: 2px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.headerButtons {
  display: flex;
  gap: 18px;
}

.header {
  border-bottom: 1px solid var(--color-light-grey);
  padding: 16px;
}

.filtersContainer {
  display: flex;
  align-items: center;
}

.tableContent {
  padding: 16px;
  background: white;
  border: 1px solid #d9d9d9;
  box-shadow: 0px 2px 4px rgb(0 0 0 / 6%);
  border-radius: 2px;
}

.table {
  tr th {
    padding: 16px 8px !important;
  }
}

.divider {
  height: 2em;
  margin-right: 16px;
}
