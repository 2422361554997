.fileWrapper {
  display: flex;
  flex-direction: column;
  height: 104px;
  width: 120px;
  padding: 0;
  gap: 0;
}
.serviceAppointmentTypeTag {
  display: flex;
  padding: 0px 8px;
  margin: 0px 8px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border-radius: 10px;
  background: var(--neutral-4, #f0f0f0);
}
.iconCentered {
  width: 100%;
  height: 168px;
  padding-right: 16px;
  display: flex;
  align-items: center;
  justify-content: center;
}
