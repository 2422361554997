.contentLayout {
  height: 100%;
}

.header {
  padding: 16px;
}

.headerExtras {
  border-bottom: 1px solid var(--color-light-grey);
  padding: 0px 16px 16px 16px;
}

.worktypeFilterTree {
  :global {
    .ant-select-arrow,
    .ant-select-clear {
      margin-right: 4px;
    }
  }
}

.wrapper {
  padding: 0 16px 16px 16px;
  overflow: auto;
  background: var(--background-4);
}

.statusText {
  text-transform: uppercase;
  border-radius: 20px;
}

.tableContent {
  margin-top: 16px;
  padding: 16px;
  background: white;
  box-shadow: 0px 0.333333px 16px rgba(0, 0, 0, 0.01),
    0px 1.25px 5px rgba(0, 0, 0, 0.06);
  border-radius: 4px;
}

.table {
  tr th {
    padding: 16px 8px !important;
  }
}

.tableRowItemHighlighted {
  border-left: 3px solid #f05353;
  background-color: rgb(240, 83, 83, 0.05);
}

.filtersContainer {
  padding: 4px 0px 20px 0px;
  background: white;
  display: flex;
  justify-content: space-between;
}
.filtersCheckbox {
  margin: 0 16px;
}
.checkboxDivider {
  height: 2em;
}
