.detailWrapper {
  display: flex;
  height: 38px;
  padding: 6px 0px;
  justify-content: space-between;
  align-items: center;
  align-self: stretch;
  border-bottom: 1px solid var(--neutral-4, #f0f0f0);
  background: var(--primary-color-set-snow-white, #fff);
}
.flexCenter {
  display: flex;
  align-items: center;
}
