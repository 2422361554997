@import '../../variables';

$groupheader-height: 88px;

.contentLayout {
  height: 100%;
}

.groupContent {
  box-shadow: 0px 0.333333px 16px rgba(0, 0, 0, 0.253),
    0px 1.25px 5px rgba(0, 0, 0, 0.181);
  border-bottom: 1px solid rgba(0, 0, 0, 0.061);
  width: 100%;
  background-color: white;
  height: $groupheader-height;
}

.dataContent {
  padding: 16px;
  overflow: auto;
  background: #f3f4f6;
  height: calc(100% - #{$groupheader-height});
  box-shadow: 0px 0.333333px 16px rgba(0, 0, 0, 0.01),
    0px 1.25px 5px rgba(0, 0, 0, 0.06);
  border-radius: 4px;
}

.statusText,
.appointmentStatusText {
  text-transform: uppercase;
  border-radius: 20px;
}

.appointmentStatusText {
  margin-left: 10px;
}

.filtersContainer {
  overflow-x: auto;
  padding-bottom: 16px;
  margin-bottom: 4px;
  display: flex;
  justify-content: space-between;
  white-space: nowrap;
  div {
    display: contents;
  }
}

.breadcrumbContainer {
  margin: 10px 0 0 32px;
}

.checkboxText {
  font-size: small;
  label {
    display: inline-block;
  }
}

.checkboxTextNumber {
  font-size: smaller;
}

.title {
  display: inline;
}

.badgeStatusColor {
  margin-right: 8px;
}

.titleContainer {
  padding: 16px;
  background: white;
  margin-bottom: 16px;
  box-shadow: 0px 0.333333px 16px rgba(0, 0, 0, 0.01),
    0px 1.25px 5px rgba(0, 0, 0, 0.06);
  border-radius: 4px;
}

.tableContent {
  padding: 16px;
  background: white;
  box-shadow: 0px 0.333333px 16px rgba(0, 0, 0, 0.01),
    0px 1.25px 5px rgba(0, 0, 0, 0.06);
  border-radius: 4px;
}

.leftSider {
  height: 100%;
  left: 0;
}

.worktypeFilterTree {
  width: 100%;
}

.autoApprovalFilterContainer {
  margin-top: 16px;
}

.tableRowItemHighlighted {
  border-left: 3px solid #f05353;
  background-color: rgb(240, 83, 83, 0.05);
}

.customLoader {
  width: 170px;
  margin-right: 8px;
}
.fieldValueCopyableText {
  :global {
    .ant-typography-copy {
      color: transparent;
    }
  }
}
.fieldValueCopyableText:hover {
  :global {
    .ant-typography-copy {
      color: #1890ff;
    }
  }
}
