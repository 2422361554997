.notesWrapper {
  width: 100%;
  display: flex;
  flex-direction: column;
  padding: 0 8px;
  max-height: 175px;
  overflow-y: auto;
}
.notesItemWrapper {
  padding: 0 8px 8px 0;
}
.serviceAppointmentTypeTag {
  font-size: 11px;
  display: flex;
  padding: 0px 8px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border-radius: 2px;
  border: 1px solid var(--neutral-5, #d9d9d9);
  background: var(--neutral-2, #fafafa);
}
.iconCentered {
  width: 100%;
  height: 168px;
  padding-right: 16px;
  display: flex;
  align-items: center;
  justify-content: center;
}
