// Time Changes Popover
.spin {
  width: 100%;
  margin: auto;
}
.canceledTime {
  color: #92929b;
  text-decoration: line-through;
}
.cancellationComment {
  color: #00000073;
}
.newestCancellationComment {
  color: #000000b4;
}
.bolderText {
  font-weight: 500;
  min-width: 37px !important;
}
.verticalAlignment {
  display: flex;
  min-width: 97px !important;
  justify-content: space-between;
}
.sectionHeader {
  font-size: 14px;
  font-weight: 500;
  padding: 8px 0;
}
.editingButton {
  margin-top: 16px;
  color: #1890ff;
  font-weight: 500;
}
.firstStartEndContainer {
  padding-top: 5px;
  margin-top: 5px;
  width: 100%;
}
.startEndContainer {
  border-top: 1px solid #f0f0f0;
  padding-top: 5px;
  margin-top: 5px;
  width: 100%;
}
.initialDateContainer {
  width: 100%;
  padding-top: 5px;
}
.dateContainer {
  width: 100%;
  border-top: 1px solid #f0f0f0;
  margin-top: 5px;
  padding-top: 5px;
}
