.greyText {
  color: #000000a6;
}

.blueText {
  color: var(--color-blue-primary);
}

.sectionContainer {
  margin-bottom: 16px;
  padding: 16px;
  background: #ffffff;
  box-shadow: 0px 0.333333px 16px rgba(0, 0, 0, 0.01),
    0px 1.25px 5px rgba(0, 0, 0, 0.06);
  border-radius: 4px;
}

.loaderContainer {
  width: 100%;
  height: 100%;
  min-height: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.sectionHeader {
  display: flex;
  justify-content: space-between;
  margin-bottom: 16px;
}

.panelHeader {
  margin-right: 16px;
  font-size: 14px;
  font-weight: 600;
}

.collapse {
  :global {
    .ant-collapse-header {
      border-bottom: none;
      padding: 0 !important;
      font-weight: 600;
      align-items: center !important;
    }

    .ant-collapse-content > .ant-collapse-content-box {
      padding: 0;
      overflow-x: hidden;
    }
  }
}
.plannerTag {
  margin: 0;
  padding: 1px 16px;
  color: black !important;
  font-size: 14px;
}
.avatar {
  min-width: 48px;
  height: 48px;
  margin-right: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #e6e9ec;
  margin-top: 24px;
}
.isLatestAvatar {
  min-width: 48px;
  height: 48px;
  margin-right: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #9ccaff;
  margin-top: 0;
}

.noteFormActionsContainer {
  display: flex;
  justify-content: flex-end;
  margin-top: 8px;
}

.serviceAppointmentTypeTag {
  font-size: 11px;
  display: flex;
  padding: 0px 8px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border-radius: 2px;
  border: 1px solid var(--neutral-5, #d9d9d9);
  background: var(--neutral-2, #fafafa);
}
.notesItemWrapper {
  padding: 0 8px 8px 16px;
}
.addNoteButtonContainer {
  padding: 0 16px 0 8px;
  margin: 8px 0;
}
.notesFilterContainer {
  padding: 0 16px 0 8px;
  margin: 8px 0 16px 0;
}
.notesContainer {
  padding: 0 16px 0 8px;
  margin: 0;
  min-height: 140px;
  overflow: auto;
}
.noteEditButton {
  margin-left: 12px;
  padding: 0;
  color: #00000073;
}
.noteFormButton {
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 20px;
}
.dateAvatarContainer {
  width: 48px;
  height: 48px;
  font-weight: 500;
  margin-right: 16px;
}

.dateAvatarMonth {
  background-color: #d26b6b;
  color: #fff;
  border-radius: 2px 2px 0px 0px;
}

.dateAvatarDay {
  background-color: #f2f2f2;
  border-radius: 0px 0px 2px 2px;
}

.center {
  display: flex;
  align-items: center;
  justify-content: center;
}
.flexStart {
  display: flex;
  align-items: center;
  justify-content: flex-start;
}

.appointmentContentLine {
  margin-bottom: 6px;
  text-align: center;
  display: flex;
  justify-items: center;
}

.divider {
  margin: 8px 0 16px 0;
}

.workOrderContentLine {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 4px;
}

.changeLogContentLine {
  margin-bottom: 8px;
}

// Time Change Row
.canceledTime {
  color: #92929b;
  text-decoration: line-through;
}
.cancellationComment {
  color: #00000073;
}
.bolderText {
  font-weight: 500;
}
.sectionHeader {
  font-size: 14px;
  font-weight: 500;
  padding: 8px 0;
}
.editingButton {
  margin-top: 16px;
  color: #1890ff;
  font-weight: 500;
}
.spin {
  width: 100%;
  margin: auto;
}
.verticalAlignment {
  display: flex;
  min-width: 97px !important;
  justify-content: space-between;
}

//checkin history

.checkinDateHeader {
  height: 46px;
  padding: 20px 0 6px 0;
  font-size: 12px;
  font-weight: 600;
  color: #777783;
}
