@import '../../variables';

.wrapper {
  display: flex;
  align-items: center;

  span {
    margin-right: var(--spacing-l);
  }
}

.status {
  color: var(--color-white);
  padding: 0 var(--spacing-l);
  border-radius: 11px;
  border: 0;
}

.content,
.highlight {
  background: var(--background-4);
  border-radius: 4px;
  padding: 16px;
  margin: 16px;
}

.highlight {
  box-shadow: inset 4px 0px 0px var(--color-red-0);
  margin-left: 32px;
}

.statusApproved {
  background-color: var(--color-green-0);
}

.statusRejected {
  background-color: var(--color-red-0);
}

.statusApproved {
  background-color: var(--color-green-0);
}

.statusInReview {
  background-color: var(--color-blue-2);
}

.message {
  padding: var(--spacing-xl);
  margin-left: var(--spacing-xxl);
  white-space: pre-line;
  word-break: break-all;
  overflow-x: hidden;
}

.commentImage {
  object-fit: 'cover';
  cursor: pointer;
  border: '1px solid var(--color-gray-1)';
  height: 80px;
  width: 80px;
}
