.appointmentCardContainer {
  width: 100%;
  display: flex;
  min-height: 80px;
  min-width: 500px;
  padding: 8px 16px;
  justify-content: space-between;
  align-items: center;
  align-self: stretch;
  border-radius: 6px;
  border: 1px solid var(--neutral-5, #d9d9d9);
  background: var(--primary-color-set-snow-white, #fff);
  box-shadow: 0px 2px 0px 0px rgba(0, 0, 0, 0.02);
}
.appointmentInformation {
  min-width: 500px;
  flex-wrap: wrap;
}
.dateTag {
  display: flex;
  padding: 8px;
  justify-content: center;
  align-items: center;
  min-width: 120px;
  border-radius: 6px;
  background: var(--primary-color-set-sky-blue-white, #3e7eff);
  /* text */
  color: var(--character-primary-inverse, #fff);
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 22px;
}
.dateTagSecondary {
  display: flex;
  padding: 8px;
  justify-content: center;
  align-items: center;
  min-width: 120px;
  border-radius: 6px;
  background: var(--tints-of-blue-sky-blue-60, #b2cbff);
  /* text */
  color: var(--character-primary-inverse, #fff);
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 22px;
}
.dateTagScheduled {
  display: flex;
  padding: 8px;
  justify-content: center;
  align-items: center;
  min-width: 120px;
  border-radius: 6px;
  background: var(--neutral-4, #f0f0f0);
  /* text */
  color: var(--character-disabled-amp-placeholder-25, rgba(0, 0, 0, 0.25));
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 22px;
}
.serviceAppointmentTypeTag {
  display: flex;
  padding: 0px 8px;
  margin: 0px 16px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border-radius: 10px;
  background: var(--neutral-4, #f0f0f0);
}
.appointmentTeam {
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  width: 180px;
  margin-right: 4px;
}
.appointmentTeamManagement {
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  width: 200px;
  margin-right: 4px;
}
.alignedIcons {
  display: flex;
  padding: 0 4px;
  height: 100%;
  align-items: flex-start;
}
.flexCenter {
  display: flex;
  height: 100%;
  align-items: center;
}
.moreButton {
  display: flex;
  padding: 8px;
  justify-content: center;
  align-items: center;
  gap: 4px;
  border: none !important;
  background: none !important;
  box-shadow: none !important;
  /* text */
  font-size: 14px;
  color: var(--primary-color-set-sky-blue-white, #3e7eff);
  text-align: center;
  font-weight: 400;
  line-height: 22px;
}
